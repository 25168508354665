import React, {Component, lazy } from 'react';
import {Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { TitleComponent } from '../TitleComponent';

// withTitle function
const withTitle = (props: any) => {
    return class Title extends Component {
        render() {
            return (
                <React.Fragment>
                    <TitleComponent title={props.title} />
                    <props.component/>
                </React.Fragment>
            );
        }
    };
};

const Login = lazy(() => import("../../Login"));
const Dashboard = lazy(() => import("../../Dashboard"));
const Profile = lazy(() => import("../../Profile"));
const Incomes = lazy(() => import('../../Incomes'));
const Savings = lazy(() => import('../../Savings'));
const Deductions = lazy(() => import('../../Deductions'));
const Expenses = lazy(() => import('../../Expenses'));
const ExpensesByCategory = lazy(() => import('../../Analytics/ExpensesByCategory/index'));
const ExpensesTrend = lazy(() => import('../../Analytics/ExpensesTrend'));
const ComparisonByCategory = lazy(() => import('../../Analytics/ComparisonByCategory'));
const AllTimeStats = lazy(() => import('../../Analytics/AllTimeStats'));
const IncomesByCategory = lazy(() => import('../../Analytics/IncomesByCategory'));
const SavingsByCategory = lazy(() => import('../../Analytics/SavingsByCategory'));
const DeductionsByCategory = lazy(() => import('../../Analytics/DeductionsByCategory'));
const PaymentMethods = lazy(() => import('../../Analytics/PaymentMethods'));
const Settings = lazy(() => import('../../Settings'));
const Accounts = lazy(() => import('../../Accounts'));

const LoginComponent = withTitle({ component: Login, title: 'Login' });
const DashboardComponent = withTitle({ component: Dashboard, title: 'Dashboard' });
const ProfileComponent = withTitle({ component: Profile, title: 'Profile' });
const IncomesComponent = withTitle({ component: Incomes, title: 'Incomes' });
const SavingsComponent = withTitle({ component: Savings, title: 'Savings' });
const DeductionsComponent = withTitle({ component: Deductions, title: 'Deductions' });
const ExpensesComponent = withTitle({ component: Expenses, title: 'Expenses' });
const ExpensesByCategoryComponent = withTitle({ component: ExpensesByCategory, title: 'Expenses By Category' });
const ExpensesTrendComponent = withTitle({ component: ExpensesTrend, title: 'Expenses Trend' });
const ComparisonByCategoryComponent = withTitle({ component: ComparisonByCategory, title: 'Comparision By Category' });
const AllTimeStatsComponent = withTitle({ component: AllTimeStats, title: 'All TIme Stats' });
const IncomesByCategoryComponent = withTitle({ component: IncomesByCategory, title: 'Incomes By Category' });
const SavingsByCategoryComponent = withTitle({ component: SavingsByCategory, title: 'Savings By Category' });
const DeductionsByCategoryComponent = withTitle({ component: DeductionsByCategory, title: 'Deductions By Category' });
const PaymentMethodsComponent = withTitle({ component: PaymentMethods, title: 'Payment Methods' });
const SettingsComponent = withTitle({ component: Settings, title: 'Settings' });
const AccountsComponent = withTitle({ component: Accounts, title: 'Accounts' });

const Routes = () => {
    return (
        <Switch>
            <PublicRoute restrictedForSignedInUser exact path={'/'} component={LoginComponent} />
            <PrivateRoute exact path={'/dashboard'} component={DashboardComponent} />
            <PrivateRoute exact path={'/expenses'} component={ExpensesComponent} />
            <PrivateRoute exact path={'/analytics/expenses-by-category'} component={ExpensesByCategoryComponent} />
            <PrivateRoute exact path={'/analytics/expenses-trend'} component={ExpensesTrendComponent} />
            <PrivateRoute exact path={'/analytics/comparison-by-category'} component={ComparisonByCategoryComponent} />
            <PrivateRoute exact path={'/analytics/all-time-stats'} component={AllTimeStatsComponent} />
            <PrivateRoute exact path={'/analytics/incomes-by-category'} component={IncomesByCategoryComponent} />
            <PrivateRoute exact path={'/analytics/savings-by-category'} component={SavingsByCategoryComponent} />
            <PrivateRoute exact path={'/analytics/deductions-by-category'} component={DeductionsByCategoryComponent} />
            <PrivateRoute exact path={'/analytics/payment-methods'} component={PaymentMethodsComponent} />
            <PrivateRoute exact path={'/incomes'} component={IncomesComponent} />
            <PrivateRoute exact path={'/savings'} component={SavingsComponent} />
            <PrivateRoute exact path={'/deductions'} component={DeductionsComponent} />
            <PrivateRoute exact path={'/accounts'} component={AccountsComponent} />
            <PrivateRoute exact path={'/settings'} component={SettingsComponent} />
            <PrivateRoute exact path={'/profile'} component={ProfileComponent} />
        </Switch>
    )
}

export default Routes