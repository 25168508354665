import React, {memo} from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from "@material-ui/core/LinearProgress";
import CurrentUserMenu from "./CurrentUserMenu";
import { User } from "../../../Profile/User";
import { Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    menuButton: {
        minWidth: "35px"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    title: {
        flexGrow: 1,
        fontWeight: 500,
        fontSize: "1.2rem",
        textTransform: "capitalize"
    },
}));

interface HeaderProps extends RouteComponentProps {
    toggleDesktopDrawer: () => void
    toggleMobileDrawer: () => void
    userProfile: User
    signOut: () => void
    showPageLoader: boolean
}

const Header = (props: HeaderProps) => {
    const classes = useStyles();

    const renderTitle = () => {
        const pathParts = props.location.pathname.split("/");
        const title = pathParts[1]
        return (
            <p className={classes.title}>
                {title}
            </p>
        )
    }

    const renderMobileMenuToggle = () => {
        return (
            <Hidden mdUp implementation="css">
                <IconButton onClick={props.toggleMobileDrawer} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
            </Hidden>
        )
    }

    return (
        <div>
            <AppBar position="fixed" color="default" className={classes.appBar}>
                <Backdrop open={props.showPageLoader}>
                    <LinearProgress style={{
                        visibility: props.showPageLoader ? 'visible' : 'hidden'
                    }} />
                </Backdrop>
                <Toolbar>
                    <Hidden smDown implementation="css">
                        <IconButton onClick={props.toggleDesktopDrawer} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    {renderMobileMenuToggle()}
                    {renderTitle()}
                    {/* <ThemeToggler/> */}
                    <CurrentUserMenu signOut={props.signOut} userProfile={props.userProfile} />
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default memo(withRouter(Header))