import {
    AccountsActionTypes,
    FETCH_ALL_ACCOUNTS,
    EDIT_ACCOUNT,
    ADD_ACCOUNT,
    REMOVE_ACCOUNT
} from "./types";
import _ from 'lodash'
import { Account } from "../../components/Accounts/Account";

const initialState: Account[] = []

const updateAccount = (accounts: Account[], accountToUpdate: Account) => {
    const accountIndex = _.findIndex(accounts, {id:accountToUpdate.id})
    accounts.splice(accountIndex, 1, accountToUpdate)
    return accounts
}

const removeAccount = (accounts: Account[], id: string) => {
    return _.remove(accounts, (e) => e.id !== id)
}

export function accountsReducer(state = initialState, action: AccountsActionTypes): Account[] {
    switch (action.type) {
        case FETCH_ALL_ACCOUNTS: {
            return action.payload
        }
        case ADD_ACCOUNT: {
            return [...state, action.payload]
        }
        case EDIT_ACCOUNT: {
            return updateAccount([...state], action.payload)
        }
        case REMOVE_ACCOUNT: {
            return removeAccount([...state], action.payload.id)
        }
        default:
            return state
    }
}