import React from 'react';
import Helmet from 'react-helmet';
import analytics from "../../services/googleAnalytics";

const TitleComponent = (props: any) => {
    var defaultTitle = 'Expenses';
    const trackPageView = () => {
        analytics.pageview(window.location.pathname);
    }

    return (
        <Helmet onChangeClientState={trackPageView}>
            <title>{props.title ? props.title : defaultTitle}</title>
        </Helmet>
    );
};

export { TitleComponent };