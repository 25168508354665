import {
    AppActionTypes,
    HIDE_NOTIFICATION,
    HIDE_PAGE_LOADER,
    Notification,
    SHOW_NOTIFICATION,
    SHOW_PAGE_LOADER
} from "./types";

export function showPageLoader(): AppActionTypes {
    return {
        type: SHOW_PAGE_LOADER
    }
}

export function hidePageLoader(): AppActionTypes {
    return {
        type: HIDE_PAGE_LOADER
    }
}

export function showNotification(message: Notification["message"], isError: Notification["isError"] = false): AppActionTypes {
    return {
        type: SHOW_NOTIFICATION,
        payload: {
            message: message,
            isError: isError
        }
    }
}

export function hideNotification(): AppActionTypes {
    return {
        type: HIDE_NOTIFICATION,
    }
}

export function showApiError(errResponse: any): AppActionTypes {
    const message: string = (errResponse && errResponse.data && errResponse.data.error) ? errResponse.data.error : "Something went wrong"
    return showNotification(message, true)
}