import React, { memo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { NavLink } from 'react-router-dom';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from '@material-ui/icons/Dashboard';
import CurrencyIcon from '../../Currency/CurrencyIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import BarChartIcon from '@material-ui/icons/BarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import InsertChartOutlinedTwoToneIcon from '@material-ui/icons/InsertChartOutlinedTwoTone';
import CompareIcon from '@material-ui/icons/Compare';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Collapse } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        borderTopRightRadius: theme.spacing(4),
        borderBottomRightRadius: theme.spacing(4),
        backgroundColor: "inherit",
        '&:hover': {
            backgroundColor: "inherit"
        }
    },
    nestedListItemLink: {
        paddingLeft: theme.spacing(7)
    },
    activeMenu: {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
            backgroundColor: theme.palette.action.selected
        }
    }
}))

type ListItemLinkProps = {
    icon: JSX.Element | null
    text: string
    to: string
    onClick: () => void
    listItemClassName?: string
}

type NavigationMenuProps = {
    closeDrawer?: () => void
}

const ListItemLink = memo((props: ListItemLinkProps) => {
    const { icon, text, to } = props;
    const classes = useStyles()

    return (
        <NavLink onClick={props.onClick} to={to} activeClassName={classes.activeMenu}>
            <ListItem button className={`${props.listItemClassName} ${classes.listItem}`}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={text} />
            </ListItem>
        </NavLink>
    );
})

const NavigationMenu = (props: NavigationMenuProps) => {
    const classes = useStyles()
    const [openedNestedMenuIndex, setOpenedNestedMenuIndex] = React.useState(-1);
    const onMenuClick = () => {
        if (openedNestedMenuIndex !== -1) {
            setOpenedNestedMenuIndex(-1)
        }
        if (props.closeDrawer) {
            props.closeDrawer()
        }
    }
    const onNestedMenuClick = () => {
        if (props.closeDrawer) {
            props.closeDrawer()
        }
    }
    const onNestedMenuTogglerClick = (index: number) => {
        if (openedNestedMenuIndex === index) {
            setOpenedNestedMenuIndex(-1)
        } else {
            setOpenedNestedMenuIndex(index)
        }
    }

    return (
        <List>
            <ListItemLink onClick={onMenuClick} to="/dashboard" text={"Dashboard"} icon={<DashboardIcon />} />
            <ListItem button onClick={() => onNestedMenuTogglerClick(0)}>
                <ListItemIcon><BarChartIcon /></ListItemIcon>
                <ListItemText primary="Analytics" />
                {openedNestedMenuIndex === 0 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={openedNestedMenuIndex === 0} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemLink
                        listItemClassName={classes.nestedListItemLink}
                        onClick={onNestedMenuClick}
                        to="/analytics/expenses-trend"
                        text={"Expenses Trend"}
                        icon={<InsertChartOutlinedTwoToneIcon />}
                    />
                    <ListItemLink
                        listItemClassName={classes.nestedListItemLink}
                        onClick={onNestedMenuClick}
                        to="/analytics/comparison-by-category"
                        text={"Vs. Chart"}
                        icon={<CompareIcon />}
                    />
                    <ListItemLink
                        listItemClassName={classes.nestedListItemLink}
                        onClick={onNestedMenuClick}
                        to="/analytics/all-time-stats"
                        text={"All Time Stats"}
                        icon={<ShowChartIcon />}
                    />
                    <ListItemLink
                        listItemClassName={classes.nestedListItemLink}
                        onClick={onNestedMenuClick}
                        to="/analytics/expenses-by-category"
                        text={"Expenses"}
                        icon={<PieChartIcon />}
                    />
                    <ListItemLink
                        listItemClassName={classes.nestedListItemLink}
                        onClick={onNestedMenuClick}
                        to="/analytics/incomes-by-category"
                        text={"Incomes"}
                        icon={<PieChartIcon />}
                    />
                    <ListItemLink
                        listItemClassName={classes.nestedListItemLink}
                        onClick={onNestedMenuClick}
                        to="/analytics/savings-by-category"
                        text={"Savings"}
                        icon={<PieChartIcon />}
                    />
                    <ListItemLink
                        listItemClassName={classes.nestedListItemLink}
                        onClick={onNestedMenuClick}
                        to="/analytics/deductions-by-category"
                        text={"Deductions"}
                        icon={<PieChartIcon />}
                    />
                    <ListItemLink
                        listItemClassName={classes.nestedListItemLink}
                        onClick={onNestedMenuClick}
                        to="/analytics/payment-methods"
                        text={"Payment Methods"}
                        icon={<PieChartIcon />}
                    />
                </List>
            </Collapse>
            <ListItemLink onClick={onMenuClick} to="/expenses" text={"Expenses"} icon={<CurrencyIcon />} />
            <ListItemLink onClick={onMenuClick} to="/incomes" text={"Incomes"} icon={<CurrencyIcon />} />
            <ListItemLink onClick={onMenuClick} to="/savings" text={"Savings"} icon={<CurrencyIcon />} />
            <ListItemLink onClick={onMenuClick} to="/deductions" text={"Deductions"} icon={<CurrencyIcon />} />
            <ListItemLink onClick={onMenuClick} to="/accounts" text={"Accounts"} icon={<AccountBalanceIcon />} />
            <ListItemLink onClick={onMenuClick} to="/settings" text={"Settings"} icon={<SettingsIcon />} />
        </List>
    )
}

export default memo(NavigationMenu)