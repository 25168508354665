import {User} from "../components/Profile/User";
import { Preferences } from "../components/Settings/Preferences/Preferences";

export const isLoggedIn = (): boolean => {
    const token: string | null = getAuthToken()

    return Boolean(token && token.trim())
}

export const getUserProfile = (): User => {
    const user: string | null = localStorage.getItem("user")
    if (user && user.trim()) {
        return JSON.parse(user)
    }

    return {
        name: '', email: '', imageUrl: undefined
    }
}

export const getUserPreferences = (): Preferences => {
    const preferences: string | null = localStorage.getItem("preferences")
    if (preferences && preferences.trim()) {
        return JSON.parse(preferences)
    }

    return {
        currency: "inr",
        defaultDeductionCategory: "",
        defaultDeductionsAccount: "",
        defaultExpenseAccount: "",
        defaultExpenseCategory: "",
        defaultExpenseSubCategory: "",
        defaultIncomeAccount: "",
        defaultIncomeCategory: "",
        defaultPaymentType: "",
        defaultSavingsAccount: "",
        defaultSavingsCategory: "",
        theme: "dark"
    }
}

export const getAuthToken = (): string | null => {
    return localStorage.getItem("auth_token")
}

export const setAuthToken = (token: string): void => {
    localStorage.setItem("auth_token", token)
}

export const setUserProfile = (profile: User): void => {
    localStorage.setItem("user", JSON.stringify(profile));
}

export const setUserPreferences = (preferences: Preferences): void => {
    localStorage.setItem("preferences", JSON.stringify(preferences))
}

export const clearAuthToken = (): void => {
    localStorage.removeItem("auth_token")
}

export const clearUserProfile = (): void => {
    localStorage.removeItem("user")
}

export const clearUserPreferences = (): void => {
    localStorage.removeItem("preferences")
}