import {
    ExpensesActionTypes,
    FETCH_ALL_EXPENSES,
    EDIT_EXPENSE,
    ADD_EXPENSE,
    REMOVE_EXPENSE
} from "./types";
import _ from 'lodash'
import { Expense } from "../../components/Expenses/Expense";

const initialState: Expense[] = []

const updateExpense = (expenses: Expense[], expenseToUpdate: Expense) => {
    const expenseIndex = _.findIndex(expenses, {id:expenseToUpdate.id})
    expenses.splice(expenseIndex, 1, expenseToUpdate)
    return expenses
}

const removeExpense = (expenses: Expense[], id: string) => {
    return _.remove(expenses, (e) => e.id !== id)
}

export function expensesReducer(state = initialState, action: ExpensesActionTypes): Expense[] {
    switch (action.type) {
        case FETCH_ALL_EXPENSES: {
            return action.payload
        }
        case ADD_EXPENSE: {
            return [...state, action.payload]
        }
        case EDIT_EXPENSE: {
            return updateExpense([...state], action.payload)
        }
        case REMOVE_EXPENSE: {
            return removeExpense([...state], action.payload.id)
        }
        default:
            return state
    }
}