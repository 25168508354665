import {
    AppActionTypes,
    AppState,
    HIDE_NOTIFICATION,
    HIDE_PAGE_LOADER,
    SHOW_NOTIFICATION,
    SHOW_PAGE_LOADER
} from "./types";

const initialState: AppState = {
    showPageLoader: false,
    notification: {
        message: "",
        show: false,
        isError: false
    }
}

export function appReducer(state = initialState, action: AppActionTypes): AppState {
    switch (action.type) {
        case SHOW_PAGE_LOADER: {
            return {
                ...state,
                showPageLoader: true
            }
        }
        case HIDE_PAGE_LOADER: {
            return {
                ...state,
                showPageLoader: false
            }
        }
        case SHOW_NOTIFICATION: {
            return {
                ...state,
                notification: {
                    show: true,
                    message: action.payload.message,
                    isError: action.payload.isError
                }
            }
        }
        case HIDE_NOTIFICATION: {
            return {
                ...state,
                notification: {
                    ...state.notification,
                    show: false,
                    message: null
                }
            }
        }
        default:
            return state
    }
}