import { Income } from "../../components/Incomes/Income"

export const FETCH_ALL_INCOMES = 'FETCH_ALL_INCOMES'
export const ADD_INCOME = 'ADD_INCOME'
export const EDIT_INCOME = 'EDIT_INCOME'
export const REMOVE_INCOME = 'REMOVE_INCOME'

interface FetchAllIncomesAction {
    type: typeof FETCH_ALL_INCOMES
    payload: Income[]
    error?: string
}

interface AddIncomeAction {
    type: typeof ADD_INCOME
    payload: Income
    error?: string
}

interface EditIncomeAction {
    type: typeof EDIT_INCOME
    payload: Income
    error?: string
}

interface DeleteIncomeAction {
    type: typeof REMOVE_INCOME
    payload: {
        id: string
    }
    error?: string
}

export type IncomesActionTypes = FetchAllIncomesAction | AddIncomeAction | EditIncomeAction | DeleteIncomeAction