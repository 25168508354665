import React, {memo} from 'react';
import NavigationMenu from "./NavigationMenu";
import Drawer from "@material-ui/core/Drawer";
import useStyles from "./DrawerStyle";

type DesktopDrawerProps = {
    isOpen: boolean
}

const DesktopDrawer = (props: DesktopDrawerProps) => {
    const classes = useStyles();

    return(
        <Drawer
            variant="permanent"
            open
            classes={{
                paper: props.isOpen ? classes.drawerPaper : classes.drawerPaperShift,
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <div className={classes.toolbar} />
            <NavigationMenu/>
        </Drawer>
    )
}

export default memo(DesktopDrawer)