import { Account } from "../../components/Accounts/Account"

export const FETCH_ALL_ACCOUNTS = "FETCH_ALL_ACCOUNTS"
export const ADD_ACCOUNT = "ADD_ACCOUNT"
export const EDIT_ACCOUNT = "EDIT_ACCOUNT"
export const REMOVE_ACCOUNT = "REMOVE_ACCOUNT"

interface FetchAllAccountsAction {
    type: typeof FETCH_ALL_ACCOUNTS
    payload: Account[]
    error?: string
}

interface AddAccountAction {
    type: typeof ADD_ACCOUNT
    payload: Account
    error?: string
}

interface EditAccountAction {
    type: typeof EDIT_ACCOUNT
    payload: Account
    error?: string
}

interface RemoveAccountAction {
    type: typeof REMOVE_ACCOUNT
    payload: {
        id: string
    }
    error?: string
}

export type AccountsActionTypes = FetchAllAccountsAction | EditAccountAction | AddAccountAction | RemoveAccountAction