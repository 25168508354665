import React, {memo} from 'react';
import Hidden from '@material-ui/core/Hidden';
import MobileDrawer from "../Drawer/MobileDrawer";
import DesktopDrawer from "../Drawer/DesktopDrawer";
import useStyles from "../Drawer/DrawerStyle";

type SidebarProps = {
    openMobileDrawer: boolean
    toggleMobileDrawer: () => void
    openDesktopDrawer: boolean
}

const Sidebar = (props: SidebarProps) => {
    const classes = useStyles();

    return(
        <nav className={classes.drawer} aria-label="dashboard categories">
            <Hidden mdUp implementation="css">
                <MobileDrawer
                    isOpen={props.openMobileDrawer}
                    close={props.toggleMobileDrawer}
                />
            </Hidden>
            <Hidden smDown implementation="css">
                <DesktopDrawer
                    isOpen={props.openDesktopDrawer}
                />
            </Hidden>
        </nav>
    )
}

export default memo(Sidebar)