import { ThemeOptions } from "@material-ui/core/styles"

const commonStyles: any = {
    typography: {
        fontFamily: [
            'Comfortaa', 'cursive'
        ].join(',')
    },
    overrides: {
        MuiFormControl: {
            root: {
                marginBottom: "0.5rem"
            }
        },
        MuiDialogTitle: {
            root: {
                paddingBottom: "0px"
            }
        },
        MuiDialogContent: {
            root: {
                paddingTop: "0px"
            }
        },
        MuiCardHeader: {
            title: {
                fontSize: "1rem"
            },
            root: {
                padding: "1rem",
                paddingBottom: 0,
            }
        },
        MuiCardContent: {
            root: {
                padding: "1rem"
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                padding: 0
            },
            content: {
                margin: 0
            },
            expandIcon: {
                padding: 0
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: 0,
                paddingLeft: "2rem",
                display:"block"
            }
        },
        MUIDataTable: {
            paper: {
                overflowX: "auto"
            }
        },
        MUIDataTableToolbar: {
            titleText: {
                fontSize: "1rem"
            }
        },
        MuiTab: {
            wrapper: {
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row'
            },
            labelIcon: {
                minHeight: 'auto'
            }
        },
        MuiListItemText: {
            primary: {
                fontSize: "14px"
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: "35px",
            }
        },
        MuiListItem: {
            gutters: {
                paddingLeft: '24px',
                paddingRight: '24px'
            }
        }
    }
}

export const lightTheme: ThemeOptions = {
    ...commonStyles,
    palette: {
        type: "light"
    }
}

export const darkTheme: ThemeOptions = {
    ...commonStyles,
    palette: {
        type: "dark"
    }
}