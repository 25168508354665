import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarContent } from '@material-ui/core';

type SnackbarNotificationProps = {
    autoHideDuration?: number
    position?: SnackbarOrigin
    message: string | null
    action?: () => JSX.Element
    show: boolean
    isError?: boolean
    close?: () => void
}

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText
    }
}))

const SnackbarNotification = (props: SnackbarNotificationProps) => {
    const classes = useStyles()
    const autoHideDuration: number = 5000;
    const position: SnackbarOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    }
    const action = () => {
        if (props.close) {
            return (
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={props.close}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            )
        }

        return null;
    }

    return (
        <Snackbar
            anchorOrigin={props.position || position}
            open={props.show}
            autoHideDuration={props.autoHideDuration || autoHideDuration}
            onClose={props.close}
        >
            <SnackbarContent action={props.action ? props.action() : action()} className={clsx({[classes.error]: props.isError})} message={props.message}/>
        </Snackbar>
    )
}

export default SnackbarNotification