import { Expense } from "../../components/Expenses/Expense"

export const FETCH_ALL_EXPENSES = 'FETCH_ALL_EXPENSES'
export const ADD_EXPENSE = 'ADD_EXPENSE'
export const EDIT_EXPENSE = 'EDIT_EXPENSE'
export const REMOVE_EXPENSE = 'REMOVE_EXPENSE'

interface FetchAllExpensesAction {
    type: typeof FETCH_ALL_EXPENSES
    payload: Expense[]
    error?: string
}

interface AddExpenseAction {
    type: typeof ADD_EXPENSE
    payload: Expense
    error?: string
}

interface EditExpenseAction {
    type: typeof EDIT_EXPENSE
    payload: Expense
    error?: string
}

interface DeleteExpenseAction {
    type: typeof REMOVE_EXPENSE
    payload: {
        id: string
    }
    error?: string
}

export type ExpensesActionTypes = FetchAllExpensesAction | AddExpenseAction | EditExpenseAction | DeleteExpenseAction