import ReactGA from 'react-ga';

let gaId = "";
if (process.env.REACT_APP_GA_ID) {
    gaId = process.env.REACT_APP_GA_ID;
} else {
    console.log("GA ID not set. Analytics will not work");
}

ReactGA.initialize(gaId);

export default ReactGA;