export interface Currency {
    code: string
    name: string
    htmlEntity: string
}

export const currencies = new Map()
    .set("inr", {
        code: "inr",
        name: "INR",
        htmlEntity: "&#x20B9;"
    })
    .set("usd", {
        code: "usd",
        name: "USD",
        htmlEntity: "&#x24;"
    })