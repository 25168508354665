import {Category} from "../../components/Categories/Category";

export const FETCH_ALL_EXPENSES_CATEGORIES = 'FETCH_ALL_EXPENSES_CATEGORIES'
export const FETCH_ALL_SAVINGS_CATEGORIES = 'FETCH_ALL_SAVINGS_CATEGORIES'
export const FETCH_ALL_DEDUCTIONS_CATEGORIES = 'FETCH_ALL_DEDUCTIONS_CATEGORIES'
export const FETCH_ALL_INCOME_SOURCES = 'FETCH_ALL_INCOME_SOURCES'
export const ADD_EXPENSES_CATEGORY = 'ADD_EXPENSES_CATEGORY'
export const ADD_SAVINGS_CATEGORY = 'ADD_SAVINGS_CATEGORY'
export const ADD_DEDUCTIONS_CATEGORY = 'ADD_DEDUCTIONS_CATEGORY'
export const ADD_INCOME_SOURCE = 'ADD_INCOME_SOURCE'
export const EDIT_EXPENSES_CATEGORY = 'EDIT_EXPENSES_CATEGORY'
export const EDIT_SAVINGS_CATEGORY = 'EDIT_SAVINGS_CATEGORY'
export const EDIT_DEDUCTIONS_CATEGORY = 'EDIT_DEDUCTIONS_CATEGORY'
export const EDIT_INCOME_SOURCE = 'EDIT_INCOME_SOURCE'
export const REMOVE_EXPENSES_CATEGORY = 'REMOVE_EXPENSES_CATEGORY'
export const REMOVE_SAVINGS_CATEGORY = 'REMOVE_SAVINGS_CATEGORY'
export const REMOVE_DEDUCTIONS_CATEGORY = 'REMOVE_DEDUCTIONS_CATEGORY'
export const REMOVE_INCOME_SOURCE = 'REMOVE_INCOME_SOURCE'

export interface CategoriesState {
    expenses: Category[],
    savings: Category[],
    deductions: Category[],
    incomeSources: Category[]
}

export interface AddOrEditCategoryRequestBody {
    name: string,
    parentId?: string | null
}

interface FetchAllExpensesCategoriesAction {
    type: typeof FETCH_ALL_EXPENSES_CATEGORIES,
    payload: Category[],
    error?: string
}

interface FetchAllSavingsCategoriesAction {
    type: typeof FETCH_ALL_SAVINGS_CATEGORIES,
    payload: Category[],
    error?: string
}

interface FetchAllDeductionsCategoriesAction {
    type: typeof FETCH_ALL_DEDUCTIONS_CATEGORIES,
    payload: Category[],
    error?: string
}

interface FetchAllIncomeSourcesAction {
    type: typeof FETCH_ALL_INCOME_SOURCES,
    payload: Category[],
    error?: string
}

interface AddExpensesCategory {
    type: typeof ADD_EXPENSES_CATEGORY,
    payload: Category,
    error?: string
}

interface AddSavingsCategory {
    type: typeof ADD_SAVINGS_CATEGORY,
    payload: Category,
    error?: string
}

interface AddDeductionsCategory {
    type: typeof ADD_DEDUCTIONS_CATEGORY,
    payload: Category,
    error?: string
}

interface AddIncomeSource {
    type: typeof ADD_INCOME_SOURCE,
    payload: Category,
    error?: string
}

interface EditExpensesCategory {
    type: typeof EDIT_EXPENSES_CATEGORY,
    payload: Category,
    error?: string
}

interface EditSavingsCategory {
    type: typeof EDIT_SAVINGS_CATEGORY,
    payload: Category,
    error?: string
}

interface EditDeductionsCategory {
    type: typeof EDIT_DEDUCTIONS_CATEGORY,
    payload: Category,
    error?: string
}

interface EditIncomeSource {
    type: typeof EDIT_INCOME_SOURCE,
    payload: Category,
    error?: string
}

interface RemoveExpensesCategory {
    type: typeof REMOVE_EXPENSES_CATEGORY,
    payload: {
        id: string
    },
    error?: string
}

interface RemoveSavingsCategory {
    type: typeof REMOVE_SAVINGS_CATEGORY,
    payload: {
        id: string
    },
    error?: string
}

interface RemoveDeductionsCategory {
    type: typeof REMOVE_DEDUCTIONS_CATEGORY,
    payload: {
        id: string
    },
    error?: string
}

interface RemoveIncomeSource {
    type: typeof REMOVE_INCOME_SOURCE,
    payload: {
        id: string
    },
    error?: string
}

export type CategoriesActionTypes = FetchAllExpensesCategoriesAction | FetchAllSavingsCategoriesAction |
    FetchAllDeductionsCategoriesAction | FetchAllIncomeSourcesAction | AddExpensesCategory |
    AddSavingsCategory | AddDeductionsCategory | AddIncomeSource | EditExpensesCategory |
    EditSavingsCategory | EditDeductionsCategory | EditIncomeSource | RemoveExpensesCategory |
    RemoveSavingsCategory | RemoveDeductionsCategory | RemoveIncomeSource