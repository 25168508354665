import React, {memo, useContext} from "react";
import { getHtmlEntityFor } from "../../../utils/currencyUtils";
import CurrencyContext from "../../../context/CurrencyContext";

const CurrencyIcon = () => {
    const currencyContext = useContext(CurrencyContext)
    const currency = currencyContext.currency

    return (
        <span style={{paddingLeft: "4px"}} className="MuiSvgIcon-root" dangerouslySetInnerHTML={{ __html: getHtmlEntityFor(currency) }} />
    )
}

export default memo(CurrencyIcon)