import { Deduction } from "../../components/Deductions/Deduction"

export const FETCH_ALL_DEDUCTIONS = 'FETCH_ALL_DEDUCTIONS'
export const ADD_DEDUCTION = 'ADD_DEDUCTION'
export const EDIT_DEDUCTION = 'EDIT_DEDUCTION'
export const REMOVE_DEDUCTION = 'REMOVE_DEDUCTION'

interface FetchAllDeductionsAction {
    type: typeof FETCH_ALL_DEDUCTIONS
    payload: Deduction[]
    error?: string
}

interface AddDeductionAction {
    type: typeof ADD_DEDUCTION
    payload: Deduction
    error?: string
}

interface EditDeductionAction {
    type: typeof EDIT_DEDUCTION
    payload: Deduction
    error?: string
}

interface DeleteDeductionAction {
    type: typeof REMOVE_DEDUCTION
    payload: {
        id: string
    }
    error?: string
}

export type DeductionsActionTypes = FetchAllDeductionsAction | AddDeductionAction | EditDeductionAction | DeleteDeductionAction