import { SettingsState, SettingsActionType, FETCH_PREFERENCES, EDIT_PREFERENCES } from "./types";
import { UserActionTypes, LOGIN } from "../user/types";
import { Preferences } from "../../components/Settings/Preferences/Preferences";
import { getUserPreferences } from "../../utils/userUtils";

const getOrDefaultPreferences = (preferences: Preferences) => {
    return {
        currency: preferences.currency || "inr",
        defaultDeductionCategory: preferences.defaultDeductionCategory || "",
        defaultDeductionsAccount: preferences.defaultDeductionsAccount || "",
        defaultExpenseAccount: preferences.defaultExpenseAccount || "",
        defaultExpenseCategory: preferences.defaultExpenseCategory || "",
        defaultExpenseSubCategory: preferences.defaultExpenseSubCategory || "",
        defaultIncomeAccount: preferences.defaultIncomeAccount || "",
        defaultIncomeCategory: preferences.defaultIncomeCategory || "",
        defaultPaymentType: preferences.defaultPaymentType || "",
        defaultSavingsAccount: preferences.defaultSavingsAccount || "",
        defaultSavingsCategory: preferences.defaultSavingsCategory || "",
        theme: preferences.theme || "dark"
    }
}

const initialState: SettingsState = {
    preferences: getOrDefaultPreferences(getUserPreferences())
}

export function settingsReducer(state = initialState, action: SettingsActionType | UserActionTypes) {
    switch (action.type) {
        case LOGIN: {
            return {
                ...state,
                preferences: getOrDefaultPreferences(action.payload.preferences)
            }
        }
        case FETCH_PREFERENCES: {
            return {
                ...state,
                preferences: getOrDefaultPreferences(action.payload)
            }
        }
        case EDIT_PREFERENCES: {
            return {
                ...state,
                preferences: getOrDefaultPreferences(action.payload)
            }
        }
        default: return state
    }
}