import apiClient from "./apiClient";

export const signInWithGoogle = (idToken: string) => {
    return apiClient.post("/user/login", {
        idToken: idToken
    })
}

export const signOut = () => {
    return apiClient.post("/user/logout")
}