export const SHOW_PAGE_LOADER = 'SHOW_PAGE_LOADER'
export const HIDE_PAGE_LOADER = 'HIDE_PAGE_LOADER'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'

export interface Notification {
    show: boolean
    message: string | null
    isError: boolean
}

export interface AppState {
    showPageLoader: boolean
    notification: Notification
}

interface ShowPageLoaderAction {
    type: typeof SHOW_PAGE_LOADER
}

interface HidePageLoaderAction {
    type: typeof HIDE_PAGE_LOADER
}

interface ShowNotification {
    type: typeof SHOW_NOTIFICATION,
    payload: {
        message: Notification["message"]
        isError: Notification["isError"]
    }
}

interface HideNotification {
    type: typeof HIDE_NOTIFICATION
}

export type AppActionTypes = ShowPageLoaderAction | HidePageLoaderAction |
    ShowNotification | HideNotification