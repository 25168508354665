import { Preferences } from "../../components/Settings/Preferences/Preferences"

export const FETCH_PREFERENCES = "FETCH_PREFERENCES"
export const EDIT_PREFERENCES = "EDIT_PREFERENCES"

export interface SettingsState {
    preferences: Preferences
}

interface FetchPreferencesAction {
    type: typeof FETCH_PREFERENCES
    payload: Preferences
    error?: string
}

interface EditPreferencesAction {
    type: typeof EDIT_PREFERENCES
    payload: Preferences
    error?: string
}

export type SettingsActionType = FetchPreferencesAction | EditPreferencesAction