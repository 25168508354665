import {makeStyles} from "@material-ui/core/styles";

const drawerWidth: number = 240

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        borderRight: theme.spacing(0)
    },
    drawerPaperShift: {
        width: 0,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    toolbar: theme.mixins.toolbar,
    title: {
        paddingLeft: theme.spacing(2),
        fontWeight: 600
    }
}));

export default useStyles