import {
    DeductionsActionTypes,
    FETCH_ALL_DEDUCTIONS,
    EDIT_DEDUCTION,
    ADD_DEDUCTION,
    REMOVE_DEDUCTION
} from "./types";
import _ from 'lodash'
import { Deduction } from "../../components/Deductions/Deduction";

const initialState: Deduction[] = []

const updateDeduction = (deductions: Deduction[], deductionToUpdate: Deduction) => {
    const deductionIndex = _.findIndex(deductions, {id:deductionToUpdate.id})
    deductions.splice(deductionIndex, 1, deductionToUpdate)
    return deductions
}

const removeDeduction = (deductions: Deduction[], id: string) => {
    return _.remove(deductions, (e) => e.id !== id)
}

export function deductionsReducer(state = initialState, action: DeductionsActionTypes): Deduction[] {
    switch (action.type) {
        case FETCH_ALL_DEDUCTIONS: {
            return action.payload
        }
        case ADD_DEDUCTION: {
            return [...state, action.payload]
        }
        case EDIT_DEDUCTION: {
            return updateDeduction([...state], action.payload)
        }
        case REMOVE_DEDUCTION: {
            return removeDeduction([...state], action.payload.id)
        }
        default:
            return state
    }
}