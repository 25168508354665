import {Redirect, Route, RouteProps} from "react-router";
import React from "react";
import {isLoggedIn} from "../../../utils/userUtils";

class PrivateRoute extends Route<RouteProps> {
    render() {
        if (isLoggedIn()) {
            return <Route {...this.props}/>
        }

        return <Redirect to={'/'}/>
    }
}

export default PrivateRoute