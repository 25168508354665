import React, {memo} from 'react';
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link as RouterLink } from 'react-router-dom';
import {User} from "../../../Profile/User";

type CurrentUserMenuProps = {
    userProfile: User
    signOut: () => void
}

const CurrentUserMenu = (props: CurrentUserMenuProps) => {
    const { userProfile } = props;
    const [menuEl, setMenuEl] = React.useState(null);
    const isMenuOpen = Boolean(menuEl);

    const openMenu = (event: any) => {
        setMenuEl(event.currentTarget);
    }

    const closeMenu = () => {
        setMenuEl(null);
    }

    const signOut = () => {
        closeMenu()
        props.signOut()
    }

    return(
        <div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={openMenu}
                color="inherit"
            >
                <Avatar alt={userProfile.name} src={userProfile.imageUrl}/>
            </IconButton>
            <Menu
                anchorEl={menuEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={"current-user-menu"}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={closeMenu}
            >

                <RouterLink to={'/profile'}>
                    <MenuItem onClick={closeMenu}>Profile</MenuItem>
                </RouterLink>
                <MenuItem onClick={signOut}>Logout</MenuItem>
            </Menu>
        </div>
    )
}

export default memo(CurrentUserMenu)