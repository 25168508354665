import { appReducer } from "./app/reducers";
import { userReducer } from "./user/reducers";
import { categoriesReducer } from "./categories/reducers";
import { incomesReducer } from "./incomes/reducers";
import { savingsReducer } from "./savings/reducers";
import { deductionsReducer } from "./deductions/reducers";
import { expensesReducer } from "./expenses/reducers";
import { settingsReducer } from "./settings/reducers";
import { accountsReducer } from "./accounts/reducers";
import { Action, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk";

export const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    categories: categoriesReducer,
    incomes: incomesReducer,
    savings: savingsReducer,
    deductions: deductionsReducer,
    expenses: expensesReducer,
    settings: settingsReducer,
    accounts: accountsReducer
})

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>