import {LOGIN, LOGOUT, UserActionTypes, UserState} from "./types";
import {isLoggedIn, getUserProfile, getAuthToken} from "../../utils/userUtils";

const initialState: UserState = {
    isLoggedIn: isLoggedIn(),
    profile: getUserProfile(),
    token: getAuthToken()
}

export function userReducer(state = initialState, action: UserActionTypes): UserState {
    switch (action.type) {
        case LOGIN: {
            return{
                ...state,
                isLoggedIn: true,
                profile: action.payload.user,
                token: action.payload.token
            }
        }
        case LOGOUT: {
            return{
                ...state,
                isLoggedIn: false
            }
        }
        default:
            return state
    }
}