import { Saving } from "../../components/Savings/Saving"

export const FETCH_ALL_SAVINGS = 'FETCH_ALL_SAVINGS'
export const ADD_SAVING = 'ADD_SAVING'
export const EDIT_SAVING = 'EDIT_SAVING'
export const REMOVE_SAVING = 'REMOVE_SAVING'

interface FetchAllSavingsAction {
    type: typeof FETCH_ALL_SAVINGS
    payload: Saving[]
    error?: string
}

interface AddSavingAction {
    type: typeof ADD_SAVING
    payload: Saving
    error?: string
}

interface EditSavingAction {
    type: typeof EDIT_SAVING
    payload: Saving
    error?: string
}

interface DeleteSavingAction {
    type: typeof REMOVE_SAVING
    payload: {
        id: string
    }
    error?: string
}

export type SavingsActionTypes = FetchAllSavingsAction | AddSavingAction | EditSavingAction | DeleteSavingAction