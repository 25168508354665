import React, {memo} from 'react';
import NavigationMenu from "./NavigationMenu";
import Drawer from "@material-ui/core/Drawer";
import useStyles from "./DrawerStyle";

type MobileDrawerProps = {
    isOpen: boolean,
    close: () => void
}

const MobileDrawer = (props: MobileDrawerProps) => {
    const classes = useStyles();

    return(
        <Drawer
            variant="temporary"
            open={props.isOpen}
            onClose={props.close}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true
            }}
        >
            <div className={classes.toolbar}>
                <h3 className={classes.title}>
                    Expenses
                </h3>
            </div>
            <NavigationMenu closeDrawer={props.close}/>
        </Drawer>
    )
}

export default memo(MobileDrawer)