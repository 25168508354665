import React, {useState, memo} from 'react';
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store";
import { logoutFromServer } from "../../../store/user/actions";
import clsx from 'clsx';
import Sidebar from "./Sidebar";
import {LinearProgress} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import Header from "./Header";
import Container from "@material-ui/core/Container";

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: state.user.isLoggedIn,
    showPageLoader: state.app.showPageLoader,
    userProfile: state.user.profile
})
const mapDispatchToProps = {
    logoutFromServer
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type LayoutProps = ConnectedProps<typeof connector> & {
    children?: React.Component | React.FC | JSX.Element | null
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: theme.spacing(7),
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -240,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            paddingTop: theme.spacing(2),
            marginTop: theme.spacing(8)
        }
    },
    contentShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0
    },
    gridContainer: {
        flexGrow: 1,
    }
}));

const Layout = (props: LayoutProps) => {
    const classes = useStyles()
    const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
    const [openDesktopDrawer, setOpenDesktopDrawer] = useState(true);
    
    const toggleMobileDrawer = () => setOpenMobileDrawer(!openMobileDrawer)
    const toggleDesktopDrawer = () => setOpenDesktopDrawer(!openDesktopDrawer)

    if (!props.isLoggedIn) {
        return(
            <React.Fragment>
                {props.showPageLoader ? <LinearProgress/> : null}
                {props.children}
            </React.Fragment>
        )
    }

    return(
        <div className={classes.root}>
            <Header
                showPageLoader={props.showPageLoader}
                signOut={props.logoutFromServer}
                toggleDesktopDrawer={toggleDesktopDrawer}
                toggleMobileDrawer={toggleMobileDrawer}
                userProfile={props.userProfile}
            />
            <Sidebar
                openMobileDrawer={openMobileDrawer}
                openDesktopDrawer={openDesktopDrawer}
                toggleMobileDrawer={toggleMobileDrawer}
            />
            <Container maxWidth={'xl'} className={clsx(classes.content, {
                [classes.contentShift]: openDesktopDrawer,
            })}>
                {props.children}
            </Container>
        </div>
    )
}

export default connector(memo(Layout))