import {
    IncomesActionTypes,
    FETCH_ALL_INCOMES,
    EDIT_INCOME,
    ADD_INCOME,
    REMOVE_INCOME
} from "./types";
import _ from 'lodash'
import { Income } from "../../components/Incomes/Income";

const initialState: Income[] = []

const updateIncome = (incomes: Income[], incomeToUpdate: Income) => {
    const incomeIndex = _.findIndex(incomes, {id:incomeToUpdate.id})
    incomes.splice(incomeIndex, 1, incomeToUpdate)
    return incomes
}

const removeIncome = (incomes: Income[], id: string) => {
    return _.remove(incomes, (e) => e.id !== id)
}

export function incomesReducer(state = initialState, action: IncomesActionTypes): Income[] {
    switch (action.type) {
        case FETCH_ALL_INCOMES: {
            return action.payload
        }
        case ADD_INCOME: {
            return [...state, action.payload]
        }
        case EDIT_INCOME: {
            return updateIncome([...state], action.payload)
        }
        case REMOVE_INCOME: {
            return removeIncome([...state], action.payload.id)
        }
        default:
            return state
    }
}