import {
    CategoriesActionTypes,
    CategoriesState,
    FETCH_ALL_DEDUCTIONS_CATEGORIES,
    FETCH_ALL_EXPENSES_CATEGORIES,
    FETCH_ALL_INCOME_SOURCES,
    FETCH_ALL_SAVINGS_CATEGORIES,
    ADD_EXPENSES_CATEGORY,
    ADD_SAVINGS_CATEGORY,
    ADD_DEDUCTIONS_CATEGORY,
    ADD_INCOME_SOURCE,
    EDIT_EXPENSES_CATEGORY,
    EDIT_SAVINGS_CATEGORY,
    EDIT_DEDUCTIONS_CATEGORY,
    EDIT_INCOME_SOURCE,
    REMOVE_EXPENSES_CATEGORY,
    REMOVE_SAVINGS_CATEGORY,
    REMOVE_DEDUCTIONS_CATEGORY,
    REMOVE_INCOME_SOURCE
} from "./types";
import _ from 'lodash'
import { Category } from "../../components/Categories/Category";

const initialState: CategoriesState = {
    deductions: [], expenses: [], incomeSources: [], savings: []
}

const updateCategory = (categories: Category[], categoryToUpdate: Category) => {
    const categoryIndex = _.findIndex(categories, {id:categoryToUpdate.id})
    categories.splice(categoryIndex, 1, categoryToUpdate)
    return categories
}

const removeCategory = (categories: Category[], id: string) => {
    return _.remove(categories, (e) => e.id !== id)
}

export function categoriesReducer(state = initialState, action: CategoriesActionTypes): CategoriesState {
    switch (action.type) {
        case FETCH_ALL_EXPENSES_CATEGORIES: {
            return {
                ...state,
                expenses: action.payload
            }
        }
        case ADD_EXPENSES_CATEGORY: {
            // Expenses Categories contains nested structure
            // FETCH_ALL_EXPENSES_CATEGORIES will be dispatched after ADD_EXPENSES_CATEGORY in ./actions
            // No need to modify state
            return state
        }
        case EDIT_EXPENSES_CATEGORY: {
            // Expenses Categories contains nested structure
            // FETCH_ALL_EXPENSES_CATEGORIES will be dispatched after EDIT_EXPENSES_CATEGORY in ./actions
            // No need to modify state
            return state
        }
        case REMOVE_EXPENSES_CATEGORY: {
            // Expenses Categories contains nested structure
            // FETCH_ALL_EXPENSES_CATEGORIES will be dispatched after REMOVE_EXPENSES_CATEGORY in ./actions
            // No need to modify state
            return state
        }
        case FETCH_ALL_SAVINGS_CATEGORIES: {
            return {
                ...state,
                savings: action.payload
            }
        }
        case ADD_SAVINGS_CATEGORY: {
            return {
                ...state,
                savings: [...state.savings, action.payload]
            }
        }
        case EDIT_SAVINGS_CATEGORY: {
            return {
                ...state,
                savings: updateCategory([...state.savings], action.payload)
            }
        }
        case REMOVE_SAVINGS_CATEGORY: {
            return {
                ...state,
                savings: removeCategory([...state.savings], action.payload.id)
            }
        }
        case FETCH_ALL_DEDUCTIONS_CATEGORIES: {
            return {
                ...state,
                deductions: action.payload
            }
        }
        case ADD_DEDUCTIONS_CATEGORY: {
            return {
                ...state,
                deductions: [...state.deductions, action.payload]
            }
        }
        case EDIT_DEDUCTIONS_CATEGORY: {
            return {
                ...state,
                deductions: updateCategory([...state.deductions], action.payload)
            }
        }
        case REMOVE_DEDUCTIONS_CATEGORY: {
            return {
                ...state,
                deductions: removeCategory([...state.deductions], action.payload.id)
            }
        }
        case FETCH_ALL_INCOME_SOURCES: {
            return {
                ...state,
                incomeSources: action.payload
            }
        }
        case ADD_INCOME_SOURCE: {
            return {
                ...state,
                incomeSources: [...state.incomeSources, action.payload]
            }
        }
        case EDIT_INCOME_SOURCE: {
            return {
                ...state,
                incomeSources: updateCategory([...state.incomeSources], action.payload)
            }
        }
        case REMOVE_INCOME_SOURCE: {
            return {
                ...state,
                incomeSources: removeCategory([...state.incomeSources], action.payload.id)
            }
        }
        default:
            return state
    }
}