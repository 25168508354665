import { batch } from "react-redux";
import { LOGIN, LOGOUT } from "./types";
import { signInWithGoogle, signOut } from "../../services/userService";
import { AppThunk } from "../index";
import { hidePageLoader, showNotification, showPageLoader } from "../app/actions";
import { clearAuthToken, clearUserProfile, setAuthToken, setUserProfile, setUserPreferences, clearUserPreferences } from "../../utils/userUtils";
import analytics from "../../services/googleAnalytics";

export const loginInWithGoogle = (idToken: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await signInWithGoogle(idToken)

        setAuthToken(response.data.data.token)
        setUserProfile(response.data.data.user)
        setUserPreferences(response.data.data.preferences)

        batch(() => {
            dispatch({
                type: LOGIN,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
            analytics.event({
                category: "user",
                action: "login",
                label: "success"
            })
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showNotification("Unable to sign in"))
            analytics.event({
                category: "user",
                action: "login",
                label: "failure"
            })
        })
    }
}

export const logoutFromServer = (): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await signOut()

        clearAuthToken()
        clearUserProfile()
        clearUserPreferences()

        batch(() => {
            dispatch({
                type: LOGOUT
            })
            dispatch(hidePageLoader())
            analytics.event({
                category: "user",
                action: "logout",
                label: "success"
            })
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showNotification("Unable to sign out"))
            analytics.event({
                category: "user",
                action: "logout",
                label: "failure"
            })
        })
    }
}