import React, { useState, useEffect, Suspense } from 'react';
import ThemeContext from "../../context/ThemeContext";
import 'typeface-comfortaa';
import { MuiThemeProvider as ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightTheme, darkTheme } from './themes';
import './index.css';
import Layout from "./Layout";
import { BrowserRouter } from "react-router-dom";
import { hideNotification } from "../../store/app/actions";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import Routes from './Routes';
import SnackbarNotification from "./Notifications/SnackbarNotification";
import CurrencyContext from '../../context/CurrencyContext';
import ServiceWorker from "./ServiceWorker";

const mapStateToProps = (state: RootState) => ({
    notification: state.app.notification,
    theme: state.settings.preferences.theme,
    currency: state.settings.preferences.currency
})
const mapDispatchToProps = {
    hideNotification
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type AppProps = ConnectedProps<typeof connector>

const App = (props: AppProps) => {
    const [theme, setTheme] = useState(props.theme)
    const [currency, setCurrency] = useState(props.currency)
    const changeTheme = (changeTo: string): void => setTheme(changeTo)
    const themeContextValue = { theme, changeTheme }
    const currencyContextValue = { currency }
    
    useEffect(() => {
        setTheme(props.theme)
    }, [props.theme])

    useEffect(() => {
        setCurrency(props.currency)
    }, [props.currency])

    return (
        <ThemeProvider theme={createMuiTheme(theme === 'dark' ? darkTheme : lightTheme)}>
            <ThemeContext.Provider value={themeContextValue}>
                <CurrencyContext.Provider value={currencyContextValue}>
                    <CssBaseline />
                    <BrowserRouter>
                        <Layout>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Routes />
                        </Suspense>
                        </Layout>
                        <SnackbarNotification
                            isError={props.notification.isError}
                            message={props.notification.message}
                            show={props.notification.show}
                            close={props.hideNotification}
                        />
                        <ServiceWorker/>
                    </BrowserRouter>
                </CurrencyContext.Provider>
            </ThemeContext.Provider>
        </ThemeProvider>
    )
}

export default connector(App)