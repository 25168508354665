import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './store';
import App from './components/App';

const middlewares = [thunk]

if (process.env.NODE_ENV === 'development') {
  const { logger } = require("redux-logger")

  middlewares.push(logger)
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
