import {
    SavingsActionTypes,
    FETCH_ALL_SAVINGS,
    EDIT_SAVING,
    ADD_SAVING,
    REMOVE_SAVING
} from "./types";
import _ from 'lodash'
import { Saving } from "../../components/Savings/Saving";

const initialState: Saving[] = []

const updateSaving = (savings: Saving[], savingToUpdate: Saving) => {
    const savingIndex = _.findIndex(savings, {id:savingToUpdate.id})
    savings.splice(savingIndex, 1, savingToUpdate)
    return savings
}

const removeSaving = (savings: Saving[], id: string) => {
    return _.remove(savings, (e) => e.id !== id)
}

export function savingsReducer(state = initialState, action: SavingsActionTypes): Saving[] {
    switch (action.type) {
        case FETCH_ALL_SAVINGS: {
            return action.payload
        }
        case ADD_SAVING: {
            return [...state, action.payload]
        }
        case EDIT_SAVING: {
            return updateSaving([...state], action.payload)
        }
        case REMOVE_SAVING: {
            return removeSaving([...state], action.payload.id)
        }
        default:
            return state
    }
}